



























































import { Vue, Component, PropSync } from 'vue-property-decorator';
import AccountStatusErrorAllianzProcessViewModel
  from '@/vue-app/view-models/components/contract-savings/allianz/account-status-error-allianz-process-view-model';

@Component({
  name: 'AccountStatusErrorAllianzProcess',
  components: {
    AllianzProcessManualReviewRequestSent: () => import('@/vue-app/components/contract-savings/allianz/allianz-process-manual-review-request-sent.vue'),
  },
})
export default class AccountStatusErrorAllianzProcess extends Vue {
  @PropSync('isOpen', { type: Boolean, required: true })
  private is_open!: boolean;

  error_allianz_process_view_model = Vue.observable(
    new AccountStatusErrorAllianzProcessViewModel(this),
  );
}
