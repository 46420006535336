import Vue from 'vue';
import i18n from '@/vue-app/plugins/i18n';

export default class AccountStatusErrorAllianzProcessViewModel {
  private readonly i18n_namespace = 'components.contract-savings.allianz.account-status-error-allianz-process';

  private readonly view: Vue;

  manual_review = true;

  constructor(view: Vue) {
    this.view = view;
  }

  translate = (value: string) => (
    i18n.t(`${this.i18n_namespace}.${value}`) as string
  )

  requestManualReview = () => {
    // TODO: call service to request manual review
    this.manual_review = true;
  }

  retryAccountStatusUpload = () => {
    this.view.$emit('retryAccountStatusUpload');
  }
}
