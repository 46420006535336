import { render, staticRenderFns } from "./account-status-error-allianz-process.vue?vue&type=template&id=50e933d0&"
import script from "./account-status-error-allianz-process.vue?vue&type=script&lang=ts&"
export * from "./account-status-error-allianz-process.vue?vue&type=script&lang=ts&"
import style0 from "./account-status-error-allianz-process.vue?vue&type=style&index=0&module=true&lang=css&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCol,VDialog,VImg,VRow})
